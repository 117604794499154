.App {
  display: flex;
  height: 100vh;
  flex-direction: column;
}

#connect-container {
  width: 100%;
  height: 100%;
  min-width: 432px;
}

#citrix-banner {
  width: 100%;
  height: 60px;
  background-color: tomato;
}

#citrix-banner h3 {
  color: white;
  text-align: center;
}

#acknowledge-container {
  position: absolute;
  top:0;
  left: 0;
  background-color: rgba(.3,.3,.3,.6);
  width: 100vw;
  height: 100vh;
}


#acknowledge-dialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 200px;
  width: 500px;
  border: 1px solid tomato;
  position: absolute;
  top: calc(50% - 200px);
  left: calc(50% - 250px);
  background-color: white;
  box-shadow: black;
}

#acknowledge-text {
  padding: 15px;
}

#acknowledge-button {
  margin-top: 20px;
  background-color: tomato;
  color: white;
  font-weight: bold;
  padding: 15px;
  border: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
